<script>
import PopOverSearch from './../../../routes/dashboard/conversation/search/PopOverSearch.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import wootConstants from 'dashboard/constants/globals';
import { useUISettings } from 'dashboard/composables/useUISettings';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import WebphoneMinimized from './WebphoneMinimized.vue';

export default {
  components: {
    PopOverSearch,
    PrimaryNavItem,
    WebphoneMinimized,
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { uiSettings, updateUISettings } = useUISettings();
    return {
      uiSettings,
      updateUISettings,
    };
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
    };
  },
  computed: {
    isOnExpandedLayout() {
      const {
        LAYOUT_TYPES: { CONDENSED },
      } = wootConstants;
      const { conversationDisplayType: conversationDisplayType = CONDENSED } =
        this.uiSettings;
      return conversationDisplayType !== CONDENSED;
    },
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggleAccounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('openNotificationPanel');
    },
    toggleConversationLayout() {
      const { LAYOUT_TYPES } = wootConstants;
      const {
        conversationDisplayType:
          conversationDisplayType = LAYOUT_TYPES.CONDENSED,
      } = this.uiSettings;
      const newViewType =
        conversationDisplayType === LAYOUT_TYPES.CONDENSED
          ? LAYOUT_TYPES.EXPANDED
          : LAYOUT_TYPES.CONDENSED;
      this.updateUISettings({
        conversationDisplayType: newViewType,
        previouslyUsedConversationDisplayType: newViewType,
      });
    },
  },
};
</script>

<template>
  <div v-if="isSidebarOpen" class="w-full">
    <div class="w-full flex justify-around items-center mt-2">
      <PrimaryNavItem
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
      <PopOverSearch
        :is-on-expanded-layout="isOnExpandedLayout"
        @toggleConversationLayout="toggleConversationLayout"
      />
      <WebphoneMinimized />
    </div>
  </div>
</template>
