<script>
import { mapGetters } from 'vuex';
import { frontendURL } from 'dashboard/helper/URLHelper';
export default {
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    searchUrl() {
      return frontendURL(`accounts/${this.accountId}/search`);
    },
  },
};
</script>

<template>
  <div class="relative">
    <div>
      <router-link
        v-tooltip.bottom="$t(`CONVERSATION.SEARCH_MESSAGES`)"
        :to="searchUrl"
        class="text-slate-700 rounded-lg dark:text-slate-100 w-10 h-8 flex items-center justify-center hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
      >
        <div class="flex">
          <fluent-icon
            icon="search"
            class="search--icon text-slate-800 dark:text-slate-200"
            size="16"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-link {
  &:hover {
    .search--icon,
    .search--label {
      @apply hover:text-woot-500 dark:hover:text-woot-500;
    }
  }
}
</style>
